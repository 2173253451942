angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.electronUploadDocument', {
            url: '/electronUploadDocument',
            views: {
                "main": {
                    controller: 'electronUploadDocumentCtrl',
                    templateUrl: 'web/main/electronUploadDocument/electronUploadDocument.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Upload Document'
            }
        });
    })

    .controller('electronUploadDocumentCtrl', function ($scope, $state, sendSelectTypeModal, session) {
        var that = this;

        var argv = require('electron').remote.process.argv;
        
        that.loading = true;
        var fs = require('fs');
        var FormData = require('form-data');
        var request = require('request');

        var send = (file, documento) => {
            var headers = {
                'Content-Type': undefined,
                'Authorization': 'Bearer ' + session.get().token
            };

            /*
            var form = new FormData();
            form.append('file', fs.createReadStream(file, { filename: 'name' }));
            form.append('sign', JSON.stringify(documento));
            */
            var form = {
                'file' : fs.createReadStream(file),
                'sign': JSON.stringify(documento)
            };

            request.post({ url: FingerinkEnvironmentAngular.apiUrl + (FingerinkEnvironmentAngular.disableApiVersion?'':(FingerinkVersion + '/')) + 'signatures/', headers: headers, formData: form }, function (err, resp, body) {
                if (err) {
                    console.log('Error!');
                } else {
                    that.loading = false;
                    sendSelectTypeModal.openModal().result.then((r) => $state.go('main.send', { document: JSON.parse(body).id, type: r }));
                }
            });
        };

        if(!argv[2]){
            $state.go('main.dashboard');
        }

        send(argv[2], {
            subject: argv[2].replace(/^.*[\\\/]/, '')
        });
        

    });
